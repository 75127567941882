// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";
// common
import { AdvancedSearchFilter, IAdvancedSearchFilter, IAdvancedSearchReferenceData } from "../../../../../common/advancedSearch/models";

export enum ViewStateEnum {
    Initialising,
    View,
    Searching,
    SelectBatchUpdate,
    EditBatchUpdate,
}

export interface IBatchUpdateViewModel {
    updateConfidentiality: boolean;
    confidentiality?: boolean | undefined;
    updateConfidentialityRemarks: boolean;
    confidentialityRemarksUpdateType: Client.SurveyDataStringUpdateTypeEnum;
    confidentialityRemarks?: string | undefined;
    updateReceivedDate: boolean;
    receivedDate?: Date | undefined;
    updateConsignment: boolean;
    consignmentUpdateType: Client.SurveyDataStringUpdateTypeEnum;
    consignment?: string | undefined;
    updateConditionStatus: boolean;
    conditionStatus: Client.GetGeoscienceAustraliaSurveyDataListReferenceDataConditionStatusDto | undefined;
    updateLoanStatus: boolean;
    loanStatus: Client.GetGeoscienceAustraliaSurveyDataListReferenceDataLoanStatusDto | undefined;
}

export interface IReferenceData {
    loanStatuses: Client.GetGeoscienceAustraliaSurveyDataListReferenceDataLoanStatusDto[];
    conditionStatuses: Client.GetGeoscienceAustraliaSurveyDataListReferenceDataConditionStatusDto[];
}

export interface ISurveyDataItemViewModel {
    id: number;
    versionNumber: number;
    itemId: string;
    title: string;
    publicTitle: string | undefined;
    dataType: string | undefined;
    tapeId: string | undefined;
    receivedDate: Date | undefined;
    numberOfMappings: number;
    numberOfAttachments: number;
    isConfidential: string | undefined;
    confidentialityRemarks: string;
    conditionStatus: string | undefined;
    loanStatus: string | undefined;
    consignment: string | undefined;

    isSelected: boolean;
    updateFailed: boolean;
    relatedActivities: Client.GetGeoscienceAustraliaSurveyDataListRelatedActivityDto[];
    relatedActivitiesString: string | undefined;
}

export interface IRootViewModel {
    surveyData: ISurveyDataItemViewModel[];
    isResultSetLimited: boolean;

    referenceData: IReferenceData;

    canViewBoreholes: boolean;
    canViewAcquisitions: boolean;
    canViewProjects: boolean;

    searchText: string;
    advancedSearchFilter?: IAdvancedSearchFilter | undefined;
    advancedSearchReferenceData: IAdvancedSearchReferenceData;

    batchUpdateDetails: IBatchUpdateViewModel;

    statusMessages: Client.StatusMessagesDto | undefined;

    viewState: ViewStateEnum;

    isDirty: boolean;
    isConflict: boolean;

    initialise(): IRootViewModel;
    refreshList(response: Client.GetGeoscienceAustraliaSurveyDataListResponseDto): IRootViewModel;
    refreshReferenceData(response: Client.GetGeoscienceAustraliaSurveyDataListReferenceDataResponseDto): IRootViewModel;
    refreshUpdate(response: Client.UpdateGeoscienceAustraliaSurveyDataBatchResponseDto): IRootViewModel;

    changeSearchFilter(searchText: string, advancedSearchFilter: IAdvancedSearchFilter): IRootViewModel;
    clearAllSearchFilters(): IRootViewModel;

    refreshConflict(): IRootViewModel;

    onSurveyDataUpdated(surveyData: ISurveyDataItemViewModel): IRootViewModel;
    onBatchDetailsUpdate(batchUpdateDetails: IBatchUpdateViewModel): IRootViewModel;

    onSelectBatchUpdate(): IRootViewModel;
    onEditBatchUpdate(): IRootViewModel;
}

export class SurveyDataItemViewModel implements ISurveyDataItemViewModel {
    constructor(surveyData: Client.GetGeoscienceAustraliaSurveyDataListItemDto) {
        this.id = surveyData.id;
        this.versionNumber = surveyData.versionNumber;
        this.itemId = surveyData.archiveId;
        this.publicTitle = surveyData.publicTitle;
        this.dataType = surveyData.dataType;
        this.receivedDate = surveyData.receivedDate;
        this.numberOfMappings = surveyData.numberOfMappings;
        this.numberOfAttachments = surveyData.numberOfAttachments;
        this.consignment = surveyData.consignment;
        this.isConfidential = surveyData.isConfidential;
        this.confidentialityRemarks = surveyData.confidentialityRemarks;
        this.title = surveyData.title;
        this.tapeId = surveyData.tapeId;
        this.isSelected = false;
        this.updateFailed = false;
        this.loanStatus = surveyData.loanStatus;
        this.conditionStatus = surveyData.conditionStatus;
        this.relatedActivities = surveyData.relatedActivities ?? [];
        this.relatedActivitiesString = surveyData.relatedActivities?.map((activity) => `${activity.identifier} - ${activity.name}`).join("|");
    }

    public id: number;
    public versionNumber: number;
    public itemId: string;
    public publicTitle: string | undefined;
    public dataType: string | undefined;
    public receivedDate: Date | undefined;
    public numberOfMappings: number;
    public numberOfAttachments: number;
    public consignment: string;
    public isConfidential: string | undefined;
    public confidentialityRemarks: string;
    public title: string;
    public tapeId: string | undefined;
    public isSelected: boolean;
    public updateFailed: boolean;
    public loanStatus: string | undefined;
    public conditionStatus: string | undefined;
    public relatedActivities: Client.GetGeoscienceAustraliaSurveyDataListRelatedActivityDto[];
    public relatedActivitiesString: string | undefined;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.surveyData = [];
        this.isResultSetLimited = false;
        this.referenceData = {
            loanStatuses: [],
            conditionStatuses: [],
        };
        this.advancedSearchReferenceData = {
            surveys: [],
            acquisitions: [],
            reprocessings: [],
            projects: [],
            enos: [],
            searchTypes: [],
            searchOperations: [],
        };

        this.searchText = "";

        this.canViewBoreholes = false;
        this.canViewAcquisitions = false;
        this.canViewProjects = false;

        this.batchUpdateDetails = {
            updateConfidentiality: false,
            confidentiality: undefined,
            updateConfidentialityRemarks: false,
            confidentialityRemarksUpdateType: Client.SurveyDataStringUpdateTypeEnum.Append,
            confidentialityRemarks: undefined,
            updateReceivedDate: false,
            receivedDate: undefined,
            updateConsignment: false,
            consignmentUpdateType: Client.SurveyDataStringUpdateTypeEnum.Append,
            consignment: undefined,
            updateConditionStatus: false,
            conditionStatus: undefined,
            updateLoanStatus: false,
            loanStatus: undefined,
        };

        this.statusMessages = undefined;

        this.viewState = ViewStateEnum.Initialising;
        this.isDirty = false;
        this.isConflict = false;
    }

    public surveyData: ISurveyDataItemViewModel[];
    public isResultSetLimited: boolean;
    public referenceData: IReferenceData;

    public canViewBoreholes: boolean;
    public canViewAcquisitions: boolean;
    public canViewProjects: boolean;

    public searchText: string;
    public advancedSearchFilter?: IAdvancedSearchFilter | undefined;
    public advancedSearchReferenceData: IAdvancedSearchReferenceData;

    public batchUpdateDetails: IBatchUpdateViewModel;

    public statusMessages: Client.StatusMessagesDto | undefined;

    public viewState: ViewStateEnum;

    public isDirty: boolean;
    public isConflict: boolean;

    public initialise(): IRootViewModel {
        const vm = this._clone();

        vm.surveyData = [];
        vm.isResultSetLimited = false;

        vm.viewState = ViewStateEnum.View;
        vm.statusMessages = undefined;

        vm.isDirty = false;
        vm.isConflict = false;

        return vm;
    }
    public refreshList(response: Client.GetGeoscienceAustraliaSurveyDataListResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.surveyData = response.items.map((i) => new SurveyDataItemViewModel(i));
        vm.isResultSetLimited = response.isResultSetLimited;

        vm.canViewBoreholes = response.permissions.canViewBoreholes;
        vm.canViewAcquisitions = response.permissions.canViewAcquisitions;
        vm.canViewProjects = response.permissions.canViewProjects;

        vm.batchUpdateDetails = {
            updateConfidentiality: false,
            confidentiality: undefined,
            updateConfidentialityRemarks: false,
            confidentialityRemarksUpdateType: Client.SurveyDataStringUpdateTypeEnum.Append,
            confidentialityRemarks: undefined,
            updateReceivedDate: false,
            receivedDate: undefined,
            updateConsignment: false,
            consignmentUpdateType: Client.SurveyDataStringUpdateTypeEnum.Append,
            consignment: undefined,
            updateConditionStatus: false,
            conditionStatus: undefined,
            updateLoanStatus: false,
            loanStatus: undefined,
        };

        vm.viewState = ViewStateEnum.View;
        vm.statusMessages = undefined;

        vm.isDirty = false;
        vm.isConflict = false;

        return vm;
    }

    public refreshReferenceData(response: Client.GetGeoscienceAustraliaSurveyDataListReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.referenceData = {
            loanStatuses: response.loanStatuses,
            conditionStatuses: response.conditionStatuses,
        };

        vm.advancedSearchFilter = new AdvancedSearchFilter(response.advancedSearchTypes[0], response.advancedSearchOperations[0]);
        vm.advancedSearchReferenceData = {
            wells: [],
            wellBoreholes: [],
            titles: [],
            surveys: response.surveys,
            acquisitions: response.acquisitions,
            reprocessings: response.reprocessings,
            projects: response.projects,
            enos: response.enos,
            searchTypes: response.advancedSearchTypes,
            searchOperations: response.advancedSearchOperations,
        };
        return vm;
    }

    public refreshUpdate(response: Client.UpdateGeoscienceAustraliaSurveyDataBatchResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;

        vm.surveyData = vm.surveyData.map((r) => {
            if (!response.failedSurveyDataIds!.some((id) => id === r.id)) return r;

            return { ...r, updateFailed: true };
        });

        // reset batch update details if update succeeded
        if (response.statusMessages?.isSuccess) {
            vm.batchUpdateDetails = {
                updateConfidentiality: false,
                confidentiality: undefined,
                updateConfidentialityRemarks: false,
                confidentialityRemarksUpdateType: Client.SurveyDataStringUpdateTypeEnum.Append,
                confidentialityRemarks: undefined,
                updateReceivedDate: false,
                receivedDate: undefined,
                updateConsignment: false,
                consignmentUpdateType: Client.SurveyDataStringUpdateTypeEnum.Append,
                consignment: undefined,
                updateConditionStatus: false,
                conditionStatus: undefined,
                updateLoanStatus: false,
                loanStatus: undefined,
            };
        }

        return vm;
    }

    public changeSearchFilter(searchText: string, advancedSearchFilter: IAdvancedSearchFilter): IRootViewModel {
        const vm = this._clone();
        vm.searchText = searchText;
        vm.advancedSearchFilter = advancedSearchFilter;
        vm.viewState = ViewStateEnum.Searching;
        return vm;
    }

    public clearAllSearchFilters(): IRootViewModel {
        const vm = this._clone();
        vm.searchText = "";
        vm.advancedSearchFilter = new AdvancedSearchFilter(vm.advancedSearchReferenceData.searchTypes[0], vm.advancedSearchReferenceData.searchOperations[0]);
        vm.viewState = ViewStateEnum.Searching;
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        return vm;
    }

    public onSurveyDataUpdated(item: ISurveyDataItemViewModel): IRootViewModel {
        const vm = this._clone();

        vm.surveyData = vm.surveyData.map((r) => {
            if (r.id !== item.id) return r;
            return { ...r, isSelected: !r.isSelected };
        });

        vm.isDirty = true;
        return vm;
    }

    public onBatchDetailsUpdate(batchUpdateDetails: IBatchUpdateViewModel): IRootViewModel {
        const vm = this._clone();
        vm.batchUpdateDetails = batchUpdateDetails;
        return vm;
    }

    public onSelectBatchUpdate(): IRootViewModel {
        const vm = this._clone();
        vm.viewState = ViewStateEnum.SelectBatchUpdate;
        vm.statusMessages = undefined;
        return vm;
    }

    public onEditBatchUpdate(): IRootViewModel {
        const vm = this._clone();
        vm.viewState = ViewStateEnum.EditBatchUpdate;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.advancedSearchFilter = clone(this.advancedSearchFilter);
        return vm;
    }
}
