// models
import { IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import SimpleAuditEventsView from "../../../../../../common/audit/SimpleAuditEventsView";

interface IProps {
    vm: IRootViewModel;
}

export default function ReadOnlyFileAssetsView(props: IProps): JSX.Element {
    const { vm } = props;

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">File / Asset</h2>
                    <Forms.Row>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Tape ID" id="tapeIdField" value={vm.surveyDataFileAssets.tapeId} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Data Format" id="dataAssetFormatTypeField" value={vm.surveyDataFileAssets.surveyDataAssetFormatType?.name} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Media Type" id="mediaTypeField" value={vm.surveyDataFileAssets.surveyDataMediaType?.name} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Condition Status" id="conditionStatusField" value={vm.surveyDataFileAssets.surveyDataConditionStatus?.name} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Copy Status" id="copyStatusField" value={vm.surveyDataFileAssets.surveyDataCopyStatus?.name} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Loan Status" id="loanStatusField" value={vm.surveyDataFileAssets.surveyDataLoanStatus?.name} />
                        </Forms.ThirdColumn>
                        <Forms.FullColumn>
                            <Forms.ReadonlyTextArea label="Remarks" id="remarksField" value={vm.surveyDataFileAssets.remarks} />
                        </Forms.FullColumn>
                        <Forms.FullColumn>
                            <Forms.ReadonlyTextArea label="QC Remarks" id="qcRemarks" value={vm.surveyDataFileAssets.qcRemarks} />
                        </Forms.FullColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="RDDS Status" id="rdsStatusField" value={vm.surveyDataFileAssets.surveyDataRddsStatus?.name} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Data Status" id="dataStatusField" value={vm.surveyDataFileAssets.surveyDataDataStatus?.name} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.ReadonlyField label="Consignment" id="consignmentField" value={vm.surveyDataFileAssets.consignment} />
                        </Forms.ThirdColumn>
                        <Forms.FullColumn>
                            <Forms.ReadonlyTextArea label="RDDS Comments" id="rddsComments" value={vm.surveyDataFileAssets.rddsComments} />
                        </Forms.FullColumn>
                    </Forms.Row>
                </div>
            </div>
            <SimpleAuditEventsView simpleAuditEvents={vm.auditDto} />
        </>
    );
}
