// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../../api/Client";

export enum ViewStateEnum {
    View = "geoscienceAustralia.wellsBoreholes.surveyDataFileAssets.models.viewState.view",
    Edit = "geoscienceAustralia.wellsBoreholes.surveyDataFileAssets.models.viewState.edit",
}

export interface ISurveyDataFileAssets {
    tapeId?: string | undefined;
    remarks?: string | undefined;
    qcRemarks?: string | undefined;
    rddsComments?: string | undefined;
    consignment?: string | undefined;

    surveyDataConditionStatus?: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceData | undefined;
    surveyDataAssetFormatType?: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceData | undefined;
    surveyDataMediaType?: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceData | undefined;
    surveyDataDataStatus?: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceData | undefined;
    surveyDataRddsStatus?: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceData | undefined;
    surveyDataLoanStatus?: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceData | undefined;
    surveyDataCopyStatus?: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceData | undefined;
}

export interface IReferenceData {
    surveyDataConditionStatuses: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceData[];
    surveyDataAssetFormatTypes: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceData[];
    surveyDataMediaTypes: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceData[];
    surveyDataDataStatuses: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceData[];
    surveyDataRddsStatuses: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceData[];
    surveyDataLoanStatuses: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceData[];
    surveyDataCopyStatuses: Client.GetGeoscienceAustraliaSurveyDataDetailsReferenceData[];
}

export interface IRootViewModel {
    surveyDataId: number | undefined;
    surveyDataVersionNumber: number | undefined;
    publicTitle: string | undefined;
    itemId: string | undefined;
    pageHeader: string | undefined;

    surveyDataFileAssets: ISurveyDataFileAssets;
    referenceData: IReferenceData;

    auditDto?: Client.SimpleAuditEventsDto;

    statusMessages?: Client.StatusMessagesDto;

    isDirty: boolean;
    isConflict: boolean;
    viewState: ViewStateEnum;

    refreshFileAssets(response: Client.GetGeoscienceAustraliaSurveyDataFileAssetsResponseDto): IRootViewModel;
    refreshReferenceData(response: Client.GetGeoscienceAustraliaSurveyDataFileAssetsReferenceDataResponseDto): IRootViewModel;
    refreshUpdateResponse(response: Client.UpdateGeoscienceAustraliaSurveyDataFileAssetsResponseDto): IRootViewModel;
    refreshConflict(): IRootViewModel;

    onEditState(): IRootViewModel;
    onFileAssetsChanged(fileAssets: ISurveyDataFileAssets): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor() {
        this.surveyDataId = undefined;
        this.surveyDataVersionNumber = undefined;
        this.publicTitle = undefined;
        this.itemId = undefined;
        this.pageHeader = undefined;

        this.surveyDataFileAssets = {};
        this.referenceData = {
            surveyDataConditionStatuses: [],
            surveyDataAssetFormatTypes: [],
            surveyDataMediaTypes: [],
            surveyDataDataStatuses: [],
            surveyDataRddsStatuses: [],
            surveyDataLoanStatuses: [],
            surveyDataCopyStatuses: [],
        };

        this.isDirty = false;
        this.isConflict = false;
        this.viewState = ViewStateEnum.View;
    }

    public surveyDataId: number | undefined;
    public surveyDataVersionNumber: number | undefined;
    public publicTitle: string | undefined;
    public itemId: string | undefined;
    public pageHeader: string | undefined;

    public surveyDataFileAssets: ISurveyDataFileAssets;
    public referenceData: IReferenceData;

    public auditDto?: Client.SimpleAuditEventsDto;

    public statusMessages?: Client.StatusMessagesDto;

    public isDirty: boolean;
    public isConflict: boolean;
    public viewState: ViewStateEnum;

    public refreshFileAssets(response: Client.GetGeoscienceAustraliaSurveyDataFileAssetsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.surveyDataId = response.surveyDataId;
        vm.surveyDataVersionNumber = response.surveyDataVersionNumber;
        vm.publicTitle = response.publicTitle;
        vm.itemId = response.itemId;
        vm.pageHeader = response.publicTitle && (response.publicTitle.length > 50 ? response.publicTitle.substring(0, 47) + "..." : response.publicTitle) + ` (${response.itemId})`;

        vm.surveyDataFileAssets = {
            tapeId: response.tapeId,
            remarks: response.remarks,
            qcRemarks: response.qcRemarks,
            rddsComments: response.rddsComments,
            consignment: response.consignment,

            surveyDataConditionStatus: vm.referenceData.surveyDataConditionStatuses.find((s) => s.id === response.surveyDataConditionStatusId)!,
            surveyDataAssetFormatType: vm.referenceData.surveyDataAssetFormatTypes.find((d) => d.id === response.surveyDataAssetFormatTypeId)!,
            surveyDataMediaType: vm.referenceData.surveyDataMediaTypes.find((m) => m.id === response.surveyDataMediaTypeId)!,
            surveyDataDataStatus: vm.referenceData.surveyDataDataStatuses.find((s) => s.id === response.surveyDataDataStatusId)!,
            surveyDataRddsStatus: vm.referenceData.surveyDataRddsStatuses.find((s) => s.id === response.surveyDataRddsStatusId)!,
            surveyDataLoanStatus: vm.referenceData.surveyDataLoanStatuses.find((l) => l.id === response.surveyDataLoanStatusId)!,
            surveyDataCopyStatus: vm.referenceData.surveyDataCopyStatuses.find((s) => s.id === response.surveyDataCopyStatusId)!,
        };

        vm.statusMessages = undefined;

        vm.isDirty = false;
        vm.isConflict = false;
        vm.auditDto = response.auditDto;

        vm.viewState = ViewStateEnum.View;

        return vm;
    }

    public refreshReferenceData(response: Client.GetGeoscienceAustraliaSurveyDataFileAssetsReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.referenceData = {
            surveyDataConditionStatuses: response.surveyDataConditionStatuses,
            surveyDataAssetFormatTypes: response.surveyDataAssetFormatTypes,
            surveyDataMediaTypes: response.surveyDataMediaTypes,
            surveyDataDataStatuses: response.surveyDataDataStatuses,
            surveyDataRddsStatuses: response.surveyDataRddsStatuses,
            surveyDataLoanStatuses: response.surveyDataLoanStatuses,
            surveyDataCopyStatuses: response.surveyDataCopyStatuses,
        };
        return vm;
    }

    public refreshUpdateResponse(response: Client.UpdateGeoscienceAustraliaSurveyDataFileAssetsResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.statusMessages = response.statusMessages;
        return vm;
    }

    public refreshConflict(): IRootViewModel {
        const vm = this._clone();
        vm.isConflict = true;
        return vm;
    }

    public onEditState(): IRootViewModel {
        const vm = this._clone();
        vm.viewState = ViewStateEnum.Edit;
        vm.statusMessages = undefined;
        return vm;
    }

    public onFileAssetsChanged(fileAssets: ISurveyDataFileAssets): IRootViewModel {
        const vm = this._clone();
        vm.surveyDataFileAssets = fileAssets;
        vm.isDirty = true;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.surveyDataFileAssets = clone(this.surveyDataFileAssets);
        vm.referenceData = clone(this.referenceData);
        return vm;
    }
}
