// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import { BasicInterpTypeEnum } from "../../../../../../api/Client";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_ReportsAndData_SurveyDataCreate;

export default function* create(action: Actions.IAction<Actions.ICreateRequestedPayload>) {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);
    const { createAnother } = action.payload;

    try {
        yield put(shellHelper.createSetBusyAction());

        const createResult: CallHelper.CallResult<Client.CreateGeoscienceAustraliaSurveyDataResponseDto> = yield call(async () => await createSurveyData(vm));

        if (createResult.IsSuccess) {
            // success
            let updatedVm = vm;

            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshCreateResponse(createResult.Response!, createAnother!)));
        } else {
            // all other errors
            createResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function createSurveyData(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.CreateGeoscienceAustraliaSurveyDataResponseDto>> {
    const request = new Client.CreateGeoscienceAustraliaSurveyDataRequestDto({
        surveyAcquisitionIds: vm.createDetails.surveyAcquisitions?.map((sa) => sa.id) ?? [],
        itemId: vm.createDetails.itemId,
        surveyDataTypeId: vm.createDetails.surveyDataType!.id,
        surveyDataType: vm.createDetails.surveyDataType!.name,
        isCopiedToNopims: vm.createDetails.isCopiedToNopims,
        surveyDataTitle: vm.createDetails.surveyDataTitle,
        writtenDate: vm.createDetails.writtenDate,
        writtenDateOffset: GlobalHelpers.getDateOffsetMinutes(vm.createDetails.writtenDate),
        receivedDate: vm.createDetails.receivedDate,
        receivedDateOffset: GlobalHelpers.getDateOffsetMinutes(vm.createDetails.receivedDate),
        isConfidential: vm.createDetails.isConfidential!,
        confidentialityRemarks: vm.createDetails.confidentialityRemarks,
        basicInterpType: vm.createDetails.surveyDataBasicInterpType?.value,
        basicRelevantDate:
            vm.createDetails.surveyDataBasicInterpType?.value === BasicInterpTypeEnum.B || vm.createDetails.surveyDataBasicInterpType?.value === BasicInterpTypeEnum.Bi
                ? vm.createDetails.basicRelevantDate
                : undefined,
        basicRelevantDateOffset:
            vm.createDetails.surveyDataBasicInterpType?.value === BasicInterpTypeEnum.B || vm.createDetails.surveyDataBasicInterpType?.value === BasicInterpTypeEnum.Bi
                ? GlobalHelpers.getDateOffsetMinutes(vm.createDetails.basicRelevantDate)
                : undefined,
        interpRelevantDate:
            vm.createDetails.surveyDataBasicInterpType?.value === BasicInterpTypeEnum.I || vm.createDetails.surveyDataBasicInterpType?.value === BasicInterpTypeEnum.Bi
                ? vm.createDetails.interpRelevantDate
                : undefined,
        interpRelevantDateOffset:
            vm.createDetails.surveyDataBasicInterpType?.value === BasicInterpTypeEnum.I || vm.createDetails.surveyDataBasicInterpType?.value === BasicInterpTypeEnum.Bi
                ? GlobalHelpers.getDateOffsetMinutes(vm.createDetails.interpRelevantDate)
                : undefined,
        releasedDate: vm.createDetails.releasedDate,
        releasedDateOffset: GlobalHelpers.getDateOffsetMinutes(vm.createDetails.releasedDate),
        companyId: vm.createDetails.company?.id,
        contractorId: vm.createDetails.contractor?.id,
        tapeId: vm.createDetails.tapeId,
        surveyDataAssetFormatTypeId: vm.createDetails.surveyDataAssetFormatType?.id,
        surveyDataMediaTypeId: vm.createDetails.surveyDataMediaType?.id,
        remarks: vm.createDetails.remarks,
        surveyDataConditionStatusId: vm.createDetails.surveyDataConditionStatus?.id,
        qcRemarks: vm.createDetails.qcRemarks,
        surveyDataCopyStatusId: vm.createDetails.surveyDataCopyStatus?.id,
        surveyDataLoanStatusId: vm.createDetails.surveyDataLoanStatus?.id,
        surveyDataRddsStatusId: vm.createDetails.surveyDataRddsStatus?.id,
        rddsComments: vm.createDetails.rddsComments,
        surveyDataDataStatusId: vm.createDetails.surveyDataDataStatus?.id,
        consignment: vm.createDetails.consignment,
        inlineRange: vm.createDetails.inlineRange,
        crossLineRange: vm.createDetails.crossLineRange,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.createGeoscienceAustraliaSurveyData(request));
}
