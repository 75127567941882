// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_ReportsAndData_SurveyDataDetails;

export default function* update() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const saveResult: CallHelper.CallResult<Client.UpdateGeoscienceAustraliaSurveyDataDetailsResponseDto> = yield call(async () => await updateSurveyDataDetails(vm));

        if (saveResult.IsSuccess) {
            // success
            let updatedVm = vm;
            const r = saveResult.Response!;
            if (r.statusMessages.isSuccess) {
                const detailsResponse: CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveyDataDetailsResponseDto> = yield call(async () => await getsurveyDataDetails(vm));
                updatedVm = vm.refreshDetails(detailsResponse.Response!);
            }
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshUpdateResponse(saveResult.Response!)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function updateSurveyDataDetails(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.UpdateGeoscienceAustraliaSurveyDataDetailsResponseDto>> {
    const request = new Client.UpdateGeoscienceAustraliaSurveyDataDetailsRequestDto({
        surveyDataId: vm.surveyDataId!,
        surveyDataVersionNumber: vm.surveyDataVersionNumber!,
        title: vm.surveyDataDetails.title,
        archiveId: vm.surveyDataDetails.archiveId,
        surveyDataTypeId: vm.surveyDataDetails.surveyDataType!.id,
        surveyDataType: vm.surveyDataDetails.surveyDataType!.name,
        isCopiedToNopims: vm.surveyDataDetails.isCopiedToNopims,
        isConfidential: vm.surveyDataDetails.isConfidential!,
        tapeId: vm.surveyDataDetails.tapeId!,
        surveyDataDataFormatId: vm.surveyDataDetails.surveyDataFormat?.id!,
        confidentialityRemarks: vm.surveyDataDetails.confidentialityRemarks,
        receivedDate: vm.surveyDataDetails.receivedDate,
        receivedDateOffset: GlobalHelpers.getDateOffsetMinutes(vm.surveyDataDetails.receivedDate),
        writtenDate: vm.surveyDataDetails.writtenDate,
        writtenDateOffset: GlobalHelpers.getDateOffsetMinutes(vm.surveyDataDetails.writtenDate),
        basicInterpType: vm.surveyDataDetails.basicInterpType?.value,
        basicRelevantDate:
            vm.surveyDataDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.B || vm.surveyDataDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.Bi
                ? vm.surveyDataDetails.basicRelevantDate
                : undefined,
        basicRelevantDateOffset:
            vm.surveyDataDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.B || vm.surveyDataDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.Bi
                ? GlobalHelpers.getDateOffsetMinutes(vm.surveyDataDetails.basicRelevantDate)
                : undefined,
        interpRelevantDate:
            vm.surveyDataDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.I || vm.surveyDataDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.Bi
                ? vm.surveyDataDetails.interpRelevantDate
                : undefined,
        interpRelevantDateOffset:
            vm.surveyDataDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.I || vm.surveyDataDetails.basicInterpType?.value === Client.BasicInterpTypeEnum.Bi
                ? GlobalHelpers.getDateOffsetMinutes(vm.surveyDataDetails.interpRelevantDate)
                : undefined,
        releasedDate: vm.surveyDataDetails.releasedDate,
        releasedDateOffset: GlobalHelpers.getDateOffsetMinutes(vm.surveyDataDetails.releasedDate),
        companyId: vm.surveyDataDetails.company?.id,
        contractorId: vm.surveyDataDetails.contractor?.id,
        inlineRange: vm.surveyDataDetails.inlineRange,
        crossLineRange: vm.surveyDataDetails.crossLineRange,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.updateGeoscienceAustraliaSurveyDataDetails(request));
}

async function getsurveyDataDetails(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveyDataDetailsResponseDto>> {
    const request = new Client.GetGeoscienceAustraliaSurveyDataDetailsRequestDto({
        surveyDataId: vm.surveyDataId!,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.getGeoscienceAustraliaSurveyDataDetails(request));
}
