// framework
import { useEffect, useState } from "react";
// api
import { GetGeoscienceAustraliaSurveyDataAttachmentsItemDto } from "../../../../../../api/Client";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
// models
import { IRootViewModel, ISaveSurveyDataAttachment } from "../../models/models";
// common
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import ExternalLinkView from "../../../../../../common/externalLink/ExternalLinkView";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";

export default function SurveyDataAttachmentListGridView(props: {
    vm: IRootViewModel;
    onEdit: (updateItem: ISaveSurveyDataAttachment) => void;
    onDelete: (surveyDataAttachmentId: number) => void;
}): JSX.Element {
    const { vm, onEdit, onDelete } = props;

    const initialState: State = {
        sort: [{ field: "uploadedDate", dir: "desc" }],
        filter: { logic: "and", filters: [] },
    };

    const [gridState, setGridState] = useState(initialState);

    useEffect(() => {
        setGridState(initialState);
    }, [vm.Attachments]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid
                resizable
                data={process(vm.Attachments!, gridState)}
                {...gridState}
                onDataStateChange={(e) => {
                    setGridState(e.dataState);
                }}
            >
                <GridColumn field="name" title="Name" width={610} />
                <GridColumn field="fileName" title="File Name" width={190} />
                <GridColumn
                    field="documentLink"
                    title="Document Link"
                    width={540}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props}>
                            <ExternalLinkView className="text-nowrap" href={props.dataItem.documentLink}>
                                {props.dataItem.documentLink}
                            </ExternalLinkView>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    field="fileSize"
                    title="File Size"
                    width={140}
                    cell={(props) => <CustomGridCell gridCellProps={props}>{GlobalHelpers.convertBytesToString(props.dataItem.fileSize)}</CustomGridCell>}
                />
                <GridColumn field="uploadedDate" title="Uploaded Date" width={190} format={GlobalHelpers.NoptaDateFormatForKendo} />
                <GridColumn field="uploadedBy" title="Uploaded By" width={190} />
                <GridColumn
                    title="Edit"
                    width={60}
                    sortable={false}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <button
                                className="btn btn-secondary"
                                type="button"
                                title="Edit"
                                onClick={() => {
                                    var item = props.dataItem as GetGeoscienceAustraliaSurveyDataAttachmentsItemDto;
                                    const saveSurveyDataAttachment = {
                                        surveyDataAttachmentId: item.id,
                                        name: item.name,
                                        fileName: item.fileName,
                                        documentLink: item.documentLink,
                                    } as ISaveSurveyDataAttachment;
                                    onEdit(saveSurveyDataAttachment);
                                }}
                            >
                                Edit
                            </button>
                        </CustomGridCell>
                    )}
                />
                <GridColumn
                    title="Delete"
                    width={90}
                    sortable={false}
                    cell={(props) => (
                        <CustomGridCell gridCellProps={props} className="k-command-cell">
                            <button
                                className="btn btn-secondary"
                                type="button"
                                title="Delete"
                                onClick={() => {
                                    onDelete(props.dataItem.id);
                                }}
                            >
                                Delete
                            </button>
                        </CustomGridCell>
                    )}
                />
            </Grid>
        </>
    );
}
