// framework
import { useState } from "react";
// api
import { BasicInterpTypeEnum } from "../../../../../../api/Client";
// kendo
import { DatePicker } from "@progress/kendo-react-dateinputs";
// redux
import * as Actions from "../../redux/actions";
// models
import { IReportDetails, IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import DirtyScope from "../../../../../../common/shell/DirtyScopeController";
import { IValidationModel } from "../../../../../../common/validation/ValidationModel";
import YesNoDropDownList from "../../../../../../common/dropdowns/YesNoDropDownListView";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import KendoComboBox from "../../../../../../common/kendo/KendoComboBox";

interface IProps {
    vm: IRootViewModel;
    v: IValidationModel;
    actions: Actions.IActionFactory;
}

export default function ReportDetailsView(props: IProps): JSX.Element {
    const { vm, v, actions } = props;

    const [state, setState] = useState<IReportDetails>(vm.reportDetails);
    const [publicTitle, setPublicTitle] = useState(vm.reportDetails.publicTitle);

    function updateVm() {
        actions.viewChanged(vm.onReportDetailsChanged(state));
    }

    function updateVmAndState(updatedState: IReportDetails) {
        setState(updatedState);
        actions.viewChanged(vm.onReportDetailsChanged(updatedState));
    }

    function updatePublicTitle() {
        // default to empty string instead of undefined so that the string 'undefined' is not displayed on screen
        const reportType = state.reportType?.name ?? "";
        const reportTitle = state.reportTitle ?? "";

        // the check is in place to avoid having ';' when neither reportType nor report title is set to a value
        setPublicTitle(!reportType && !reportTitle ? "" : `${reportType}; ${reportTitle}`);
    }

    return (
        <DirtyScope scopeName="geoscienceAustralia/wellBoreholes/report/details" isDirty={vm.isDirty}>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">General</h2>
                    <Forms.Row>
                        <Forms.FullColumn>
                            <Forms.TextArea
                                label="Report Title"
                                id="reportTitleField"
                                rows={3}
                                maxLength={4000}
                                valid={v.applyValidityForKendo("ReportDetails.ReportTitle")}
                                value={state.reportTitle}
                                onChange={(value) => setState({ ...state, reportTitle: value })}
                                onBlur={() => {
                                    updateVm();
                                    updatePublicTitle();
                                }}
                            />
                            <ValidationMessageView name="ReportDetails.ReportTitle" validationModel={v} />
                        </Forms.FullColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="reportTypeField">Report Type</Forms.Label>
                            <KendoComboBox
                                id="reportTypeField"
                                value={state.reportType}
                                data={vm.referenceData.reportTypes}
                                keyField="id"
                                textField="name"
                                valid={v.applyValidityForKendo("ReportDetails.ReportType")}
                                filterable={true}
                                onChange={(event) => updateVmAndState({ ...state, reportType: event })}
                                onBlur={() => {
                                    updateVm();
                                    updatePublicTitle();
                                }}
                            />
                            <ValidationMessageView name="ReportDetails.ReportType" validationModel={v} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.TextInput
                                label="Item ID"
                                id="itemIdField"
                                maxLength={9}
                                valid={v.applyValidityForKendo("ReportDetails.ItemID")}
                                value={state.itemId}
                                onChange={(value) => setState({ ...state, itemId: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="ReportDetails.ItemID" validationModel={v} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="copiedToNopimsField">Is Copied to NOPIMS</Forms.Label>
                            <YesNoDropDownList
                                id="copiedToNopimsField"
                                allowNulls={true}
                                valid={v.applyValidityForKendo("ReportDetails.IsCopiedToNopims")}
                                value={state.isCopiedToNopims}
                                onChange={(value) => updateVmAndState({ ...state, isCopiedToNopims: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.FullColumn>
                            <Forms.Label htmlFor="publicTitleField">Public Title</Forms.Label>
                            <Forms.ReadonlyTextInput id="publicTitleField" value={publicTitle} />
                            <ValidationMessageView name="ReportDetails.PublicTitle" validationModel={v} />
                        </Forms.FullColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Confidentiality</h2>
                    <Forms.Row>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="receivedDateField">Received Date</Forms.Label>
                            <DatePicker id="receivedDateField" value={state.receivedDate} onChange={(event) => setState({ ...state, receivedDate: event.value ?? undefined })} onBlur={updateVm} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="writtenDateField">Written Date</Forms.Label>
                            <DatePicker id="writtenDateField" value={state.writtenDate} onChange={(event) => setState({ ...state, writtenDate: event.value ?? undefined })} onBlur={updateVm} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="isConfidentialField">Confidentiality</Forms.Label>
                            <YesNoDropDownList
                                id="isConfidentialField"
                                valid={v.applyValidityForKendo("ReportDetails.isConfidential")}
                                value={state.isConfidential}
                                trueValue="Confidential"
                                falseValue="Not Confidential"
                                onChange={(value) => updateVmAndState({ ...state, isConfidential: value })}
                            />
                            <ValidationMessageView name="ReportDetails.IsConfidential" validationModel={v} />
                        </Forms.ThirdColumn>
                        <Forms.FullColumn>
                            <Forms.TextArea
                                label="Confidentiality Remarks"
                                id="confidentialityRemarksField"
                                value={state.confidentialityRemarks}
                                maxLength={4000}
                                valid={v.applyValidityForKendo("ReportDetails.ConfidentialityRemarks")}
                                rows={3}
                                onChange={(value) => setState({ ...state, confidentialityRemarks: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="ReportDetails.ConfidentialityRemarks" validationModel={v} />
                        </Forms.FullColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="basicInterpTypeField">Basic Interp Type</Forms.Label>
                            <KendoComboBox
                                id="basicInterpTypeField"
                                value={state.basicInterpType}
                                data={vm.referenceData.basicInterpTypes}
                                keyField="id"
                                textField="name"
                                filterable={false}
                                suggest={false}
                                onChange={(value) => updateVmAndState({ ...state, basicInterpType: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            {state.basicInterpType?.value === BasicInterpTypeEnum.B || state.basicInterpType?.value === BasicInterpTypeEnum.Bi ? (
                                <>
                                    <Forms.Label htmlFor="basicRelevantDateField">Basic Relevant Date</Forms.Label>
                                    <DatePicker
                                        id="basicRelevantDateField"
                                        value={state.basicRelevantDate}
                                        onChange={(event) => setState({ ...state, basicRelevantDate: event.value ?? undefined })}
                                        onBlur={updateVm}
                                    />
                                </>
                            ) : (
                                <>
                                    <Forms.ReadonlyField label="Basic Relevant Date" id="basicRelevantDateField" value={""} />
                                </>
                            )}
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            {state.basicInterpType?.value === BasicInterpTypeEnum.I || state.basicInterpType?.value === BasicInterpTypeEnum.Bi ? (
                                <>
                                    <Forms.Label htmlFor="interpRelevantDateField">Interp Relevant Date</Forms.Label>
                                    <DatePicker
                                        id="interpRelevantDateField"
                                        value={state.interpRelevantDate}
                                        onChange={(event) => setState({ ...state, interpRelevantDate: event.value ?? undefined })}
                                        onBlur={updateVm}
                                    />
                                </>
                            ) : (
                                <>
                                    <Forms.ReadonlyField label="Interp Relevant Date" id="interpRelevantDateField" value={""} />
                                </>
                            )}
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="releasedDateField">Released Date</Forms.Label>
                            <DatePicker id="releasedDateField" value={state.releasedDate} onChange={(event) => setState({ ...state, releasedDate: event.value ?? undefined })} onBlur={updateVm} />
                        </Forms.ThirdColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Destructive Analysis Report</h2>
                    <Forms.Row>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="companyField">Company</Forms.Label>
                            <KendoComboBox
                                id="companyField"
                                value={state.company}
                                data={vm.referenceData.contractorCompanies}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => setState({ ...state, company: value })}
                                onBlur={updateVm}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="contractorField">Contractor</Forms.Label>
                            <KendoComboBox
                                id="contractorField"
                                value={state.contractor}
                                data={vm.referenceData.contractorCompanies}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => setState({ ...state, contractor: value })}
                                onBlur={updateVm}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.TextInput
                                label="Author"
                                id="authorField"
                                maxLength={1000}
                                valid={v.applyValidityForKendo("ReportDetails.Author")}
                                value={state.author}
                                onChange={(value) => setState({ ...state, author: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="ReportDetails.Author" validationModel={v} />
                        </Forms.ThirdColumn>
                    </Forms.Row>
                </div>
            </div>
        </DirtyScope>
    );
}
