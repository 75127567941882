// framework
import { useEffect, useState } from "react";
// redux
import * as Actions from "../../redux/actions";
import * as Models from "../../models/models";
// common
import Help from "../../../../common/help/HelpView";
import * as Forms from "../../../../common/forms/BootstrapForms";
import * as GlobalHelpers from "../../../../common/GlobalHelpers";

export function QuestionsView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const actions = props.actions;

    return (
        <div className="card mb-2">
            <div className="card-body">
                <p>
                    You have requested access to <i>Geoscience Australia</i>
                </p>
                {vm.viewState.showGeoscienceAustraliaNewAdministratorWizard && (
                    <Forms.Group>
                        <GeoscienceAustraliaAdministratorInformationView />
                    </Forms.Group>
                )}
                {vm.viewState.showGeoscienceAustraliaExistingAdministratorWizard && (
                    <Forms.Group>
                        <p className="fw-bold">Are you applying to be a Geoscience Australia Administrator?</p>
                        <GeoscienceAustraliaAdministratorRadioButtonView vm={vm} actions={actions} />
                        <GeoscienceAustraliaAdministratorInformationView />
                    </Forms.Group>
                )}
            </div>
        </div>
    );
}

function GeoscienceAustraliaAdministratorInformationView() {
    return (
        <Help title="What is a Geoscience Australia Administrator?">
            A Geoscience Australia Administrator is a role that is approved by the Repository Director and then is able to approve access for other users. A Geoscience Australia Administrator is able
            to assign all permissions within Secure Portal for Geoscience Australia users with the exception of approving another Geoscience Australia Administrator.
        </Help>
    );
}

function GeoscienceAustraliaAdministratorRadioButtonView(props: { vm: Models.IRootViewModel; actions: Actions.IActionFactory }) {
    const vm = props.vm;
    const questions = vm.user.questions;
    const actions = props.actions;

    const isGaaRequestRadioValue = "isGaaRequest";
    const isNotGaaRequestRadioValue = "isNotGaaRequest";
    const [selectedGaaRequestRadioValue, setSelectedGaaRequestRadioValue] = useState(
        GlobalHelpers.convertBooleanToRadioValue(questions.isGeoscienceAustraliaAdministratorRequest, isGaaRequestRadioValue, isNotGaaRequestRadioValue)
    );

    // update selection if changed
    useEffect(() => {
        setSelectedGaaRequestRadioValue(GlobalHelpers.convertBooleanToRadioValue(questions.isGeoscienceAustraliaAdministratorRequest, isGaaRequestRadioValue, isNotGaaRequestRadioValue));
    }, [questions.isGeoscienceAustraliaAdministratorRequest]);

    // on change
    function onChange(value: string) {
        setSelectedGaaRequestRadioValue(value);
        if (value === isGaaRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isGeoscienceAustraliaAdministratorRequest: true }));
        if (value === isNotGaaRequestRadioValue) actions.viewChanged(vm.refreshQuestionsUpdated({ isGeoscienceAustraliaAdministratorRequest: false }));
    }

    return (
        <>
            <Forms.RadioButton
                name="isGaaRequestGroup"
                id="isGaaRequestRadioField"
                value={isGaaRequestRadioValue}
                checked={selectedGaaRequestRadioValue === isGaaRequestRadioValue}
                label="Yes"
                onChange={onChange}
                inline
            />
            <Forms.RadioButton
                name="isCaRequestGroup"
                id="isNotCaRequestRadioField"
                value={isNotGaaRequestRadioValue}
                checked={selectedGaaRequestRadioValue === isNotGaaRequestRadioValue}
                label="No"
                onChange={onChange}
                inline
            />
        </>
    );
}
