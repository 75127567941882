// common
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import { IRootViewModel } from "./models";

export default function validate(vm: IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    const reportDetails = vm.reportDetails;

    const validItemIdRegex = /^[DEILMPYGXQRS][\d]{8}$/;

    if (Validation.isNullOrWhiteSpace(reportDetails.itemId)) {
        v.addError("ReportDetails.ItemID", "Item ID is required.");
    } else if (!validItemIdRegex.test(reportDetails.itemId!)) {
        v.addError(
            "ReportDetails.ItemID",
            "Item ID is invalid. It must be 9 characters long. Starting with either one of the following characters D, E, I, L, M, P, Y, G, X, Q, R or S, and the remaining characters being all digits."
        );
    }

    if (Validation.isNullOrWhiteSpace(reportDetails.publicTitle)) {
        v.addError("ReportDetails.PublicTitle", "Public Title is required.");
    }

    if (!reportDetails.reportType) {
        v.addError("ReportDetails.ReportType", "Report Type is required.");
    }

    if (reportDetails.isConfidential === undefined) {
        v.addError("ReportDetails.IsConfidential", "Confidentiality is required.");
    }

    if (reportDetails.reportTitle && reportDetails.reportTitle.length > 4000) {
        v.addError("ReportDetails.ReportTitle", "Report Title must be 4,000 characters or less.");
    }

    if (Validation.isNullOrWhiteSpace(reportDetails.confidentialityRemarks)) {
        v.addError("ReportDetails.ConfidentialityRemarks", "Confidentiality Remarks are required.");
    } else if (reportDetails.confidentialityRemarks && reportDetails.confidentialityRemarks.length > 4000) {
        v.addError("ReportDetails.ConfidentialityRemarks", "Confidentiality Remarks must be 4,000 characters or less.");
    }

    if (reportDetails.author && reportDetails.author.length > 1000) {
        v.addError("ReportDetails.Author", "Author must be 1,000 characters or less.");
    }

    return v;
}
