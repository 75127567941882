// react
import { useEffect, useState } from "react";
// kendo
import { process, State } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import CustomGridCell from "../kendoGrid/CustomGridCell";
// API
import { GetGeoscienceAustraliaReportAttachmentsItemDto, StatusMessagesDto } from "../../api/Client";
// view
import validator from "./validator";
import attachmentsValidator from "./attachmentsValidator";
// common
import ExternalLinkView from "../externalLink/ExternalLinkView";
import * as GlobalHelpers from "../GlobalHelpers";
import ConfirmationView, { DefaultButtonEnum } from "../confirmation/ConfirmationView";
import ReportAttachmentView from "./ReportAttachmentView";
import { ValidationVisibilityEnum } from "../validation/ValidationModel";
import * as toastHelper from "../../common/toastHelper";
import * as Forms from "../../common/forms/BootstrapForms";
import ValidationMessageView from "../validation/ValidationMessageView";

export class SelectableItem<T> {
    public isSelected: boolean;
    public data: T;

    constructor(item: T, isSelected?: boolean) {
        this.data = item;
        this.isSelected = isSelected !== undefined ? isSelected : false;
    }
}

export default function ReportAttachments(props: {
    attachments: GetGeoscienceAustraliaReportAttachmentsItemDto[];
    statusMessages: StatusMessagesDto | undefined;
    onEdit: (reportId: number, reportVersionNumber: number, reportAttachmentId: number, name: string, documentLink: string, fileName: string) => void;
    onDelete: (reportId: number, reportVersionNumber: number, reportAttachmentId: number) => void;
    onBatchDownload?: (reportAttachmentIds: GetGeoscienceAustraliaReportAttachmentsItemDto[]) => void;
}) {
    const { attachments, onEdit, onDelete, onBatchDownload } = props;

    const initialState: State = {
        sort: [{ field: "uploadedDate", dir: "desc" }],
        filter: { logic: "and", filters: [] },
    };

    const [gridState, setGridState] = useState(initialState);
    const [statusMessages, setStatusMessages] = useState(props.statusMessages);
    const [selectedReportAttachment, setSelectedReportAttachment] = useState<GetGeoscienceAustraliaReportAttachmentsItemDto>(new GetGeoscienceAustraliaReportAttachmentsItemDto());
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showUpdateElementDialog, setShowUpdateElementDialog] = useState(false);
    const [inBatchDownloadMode, setInBatchDownloadMode] = useState(false);
    const [selectedAttachments, setSelectedAttachments] = useState(new Array<GetGeoscienceAustraliaReportAttachmentsItemDto>());
    const [selectableAttachments, setSelectableAttachments] = useState(new Array<SelectableItem<GetGeoscienceAustraliaReportAttachmentsItemDto>>());
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);

    const v = validator(selectedReportAttachment, validationVisibility);
    const selectedAttachmentsValidator = attachmentsValidator(selectableAttachments, validationVisibility);

    useEffect(() => {
        setGridState(initialState);
    }, [selectableAttachments]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectableAttachments(getSelectableAttachments());
    }, [attachments]); // eslint-disable-line react-hooks/exhaustive-deps

    const onUpdateCancelClicked = () => {
        setShowUpdateElementDialog(false);
        setStatusMessages(undefined);
        setValidationVisibility(ValidationVisibilityEnum.Messages);
    };

    const onUpdateClicked = () => {
        if (!v.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        window.scrollTo(0, 0);
        setShowUpdateElementDialog(false);
        onEdit(
            selectedReportAttachment.reportId,
            selectedReportAttachment.reportVersionNumber,
            selectedReportAttachment.id,
            selectedReportAttachment.name!,
            selectedReportAttachment.documentLink!,
            selectedReportAttachment.fileName!
        );
    };

    const onUpdateShow = (saveReportAttachment: GetGeoscienceAustraliaReportAttachmentsItemDto) => {
        setStatusMessages(undefined);
        setSelectedReportAttachment(saveReportAttachment);
        setShowUpdateElementDialog(true);
    };

    const onDeleteShow = (reportAttachment: GetGeoscienceAustraliaReportAttachmentsItemDto) => {
        setSelectedReportAttachment(reportAttachment);
        setShowDeleteConfirmation(true);
    };

    const onDeleteCancel = () => {
        setSelectedReportAttachment(new GetGeoscienceAustraliaReportAttachmentsItemDto());
        setShowDeleteConfirmation(false);
    };

    const onDeleteAccept = () => {
        onDelete(selectedReportAttachment.reportId, selectedReportAttachment.reportVersionNumber, selectedReportAttachment.id);
        setShowDeleteConfirmation(false);
    };

    const onChange = (reportAttachment: GetGeoscienceAustraliaReportAttachmentsItemDto) => {
        setSelectedReportAttachment(reportAttachment);
    };

    const onBatchDownloadClicked = () => {
        setInBatchDownloadMode(true);
    };

    const onDownloadClicked = () => {
        if (!selectedAttachmentsValidator.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }

        setInBatchDownloadMode(false);
        setSelectedAttachments(new Array<GetGeoscienceAustraliaReportAttachmentsItemDto>());
        setSelectableAttachments(getSelectableAttachments());

        if (onBatchDownload) {
            onBatchDownload(selectedAttachments);
        }
    };

    const onCancelDownloadClicked = () => {
        for (const attachment of selectedAttachments) {
            onCheckedChanged(selectableAttachments.filter((selectableAttachment) => selectableAttachment.data.id === attachment.id)[0], false);
        }

        const updatedReportItemsState = selectableAttachments?.map((r) => {
            return { ...r, isSelected: false };
        });

        setSelectableAttachments(updatedReportItemsState);

        setSelectedAttachments(new Array<GetGeoscienceAustraliaReportAttachmentsItemDto>());
        setInBatchDownloadMode(false);
    };

    function getSelectableAttachments(isSelected?: boolean): SelectableItem<GetGeoscienceAustraliaReportAttachmentsItemDto>[] {
        return attachments.map((attachment) => new SelectableItem(attachment, isSelected));
    }

    function onCheckedChanged(item: SelectableItem<GetGeoscienceAustraliaReportAttachmentsItemDto>, newValue: boolean) {
        // find the selected/deselected attachment and update its selected state accordingly
        const updatedReportItemsState = selectableAttachments?.map((r) => {
            if (r.data.id !== item.data.id) return r;
            return { ...r, isSelected: newValue };
        });

        setSelectableAttachments(updatedReportItemsState);

        if (newValue) {
            // add the selected attachment to the list of selected attachments
            selectedAttachments.push(item.data);
            setSelectedAttachments(selectedAttachments);
        } else {
            // remove the deselected attachment from the list of selected attachments
            const list = selectedAttachments.filter((attachment) => attachment.id !== item.data.id);
            setSelectedAttachments(list);
        }
    }

    return (
        <>
            {selectableAttachments && (
                <Grid
                    resizable
                    sortable={!inBatchDownloadMode}
                    data={process(selectableAttachments!, gridState)}
                    {...gridState}
                    onDataStateChange={(e) => {
                        setGridState(e.dataState);
                    }}
                >
                    {inBatchDownloadMode && (
                        <GridColumn
                            width={32}
                            sortable={false}
                            cell={(props) => (
                                <CustomGridCell gridCellProps={props}>
                                    <input
                                        id={`isSelectedCheckBox${props.dataItem.data.id}`}
                                        type="checkbox"
                                        className={Forms.CheckInputClass}
                                        checked={props.dataItem.isSelected}
                                        onChange={() => onCheckedChanged(props.dataItem, !props.dataItem.isSelected)}
                                    />
                                </CustomGridCell>
                            )}
                        />
                    )}
                    <GridColumn field="data.name" title="Name" width={610} />
                    <GridColumn field="data.fileName" title="File Name" width={190} />
                    <GridColumn
                        title="Document Link"
                        width={540}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props}>
                                <ExternalLinkView className="text-nowrap" href={props.dataItem.data.documentLink}>
                                    {props.dataItem.data.documentLink}
                                </ExternalLinkView>
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn
                        field="fileSize"
                        title="File Size"
                        width={140}
                        cell={(props) => <CustomGridCell gridCellProps={props}>{GlobalHelpers.convertBytesToString(props.dataItem.data.fileSize)}</CustomGridCell>}
                    />
                    <GridColumn field="data.uploadedDate" title="Uploaded Date" width={190} format={GlobalHelpers.NoptaDateFormatForKendo} />
                    <GridColumn field="data.uploadedBy" title="Uploaded By" width={190} />
                    <GridColumn
                        width={170}
                        sortable={false}
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props} className="k-command-cell">
                                <button
                                    className="btn btn-secondary btn-sm"
                                    type="button"
                                    title="Edit"
                                    disabled={inBatchDownloadMode}
                                    onClick={() => {
                                        onUpdateShow(props.dataItem.data);
                                    }}
                                >
                                    Edit
                                </button>

                                <button
                                    className="btn btn-secondary btn-sm mx-2"
                                    type="button"
                                    title="Delete"
                                    disabled={inBatchDownloadMode}
                                    onClick={() => {
                                        onDeleteShow(props.dataItem.data);
                                    }}
                                >
                                    Delete
                                </button>
                            </CustomGridCell>
                        )}
                    />
                </Grid>
            )}
            {inBatchDownloadMode && <ValidationMessageView name="batchDownload.ItemSelected" validationModel={selectedAttachmentsValidator} />}
            <div className="container-fluid p-2 form">
                <div className="mt-3">
                    <>
                        <div className="row">
                            {onBatchDownload && !inBatchDownloadMode && (
                                <div className="col-auto">
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        title="Batch Download"
                                        disabled={attachments === undefined || attachments.length === 0}
                                        onClick={onBatchDownloadClicked}
                                    >
                                        Batch Download
                                    </button>
                                </div>
                            )}
                            {onBatchDownload && inBatchDownloadMode && (
                                <>
                                    <div className="col-auto">
                                        <button
                                            className="btn btn-primary"
                                            type="button"
                                            title="Batch download selected attachment files"
                                            disabled={selectedAttachments.length === 0}
                                            onClick={onDownloadClicked}
                                        >
                                            Download
                                        </button>
                                    </div>
                                    <div className="col-auto">
                                        <button className="btn btn-secondary" type="button" title="Cancel Batch download" onClick={onCancelDownloadClicked}>
                                            Cancel
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                </div>
            </div>
            {showUpdateElementDialog && (
                <ReportAttachmentView
                    reportAttachment={selectedReportAttachment!}
                    v={v}
                    statusMessages={statusMessages}
                    onSave={onUpdateClicked}
                    onCancel={onUpdateCancelClicked}
                    onChange={onChange}
                />
            )}
            {showDeleteConfirmation && (
                <ConfirmationView title="Delete Report Attachment" onAccepted={onDeleteAccept} onClosed={onDeleteCancel} defaultButton={DefaultButtonEnum.Cancel}>
                    <p>Are you sure you want to delete this report attachment?</p>
                </ConfirmationView>
            )}
        </>
    );
}
