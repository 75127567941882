// framework
import { useEffect, useState } from "react";
// api
import { CreateReportRelatedActivityTypeEnum, BasicInterpTypeEnum } from "../../../../../../api/Client";
// kendo
import { DatePicker } from "@progress/kendo-react-dateinputs";
// redux
import * as Actions from "../../redux/actions";
// models
import { ICreateDetails, IRootViewModel } from "../../models/models";
// common
import * as Forms from "../../../../../../common/forms/BootstrapForms";
import { IValidationModel } from "../../../../../../common/validation/ValidationModel";
import YesNoDropDownList from "../../../../../../common/dropdowns/YesNoDropDownListView";
import ValidationMessageView from "../../../../../../common/validation/ValidationMessageView";
import KendoComboBox from "../../../../../../common/kendo/KendoComboBox";
import KendoLargeMultiSelect from "../../../../../../common/kendo/KendoLargeMultiSelect";

interface IProps {
    vm: IRootViewModel;
    v: IValidationModel;
    actions: Actions.IActionFactory;
}

export default function ReportDetailsView(props: IProps): JSX.Element {
    const { vm, v, actions } = props;

    const [state, setState] = useState<ICreateDetails>(vm.createDetails);

    function updateVm() {
        actions.viewChanged(vm.onCreateDetailsChanged(state));
    }

    function updateVmAndState(updatedState: ICreateDetails) {
        setState(updatedState);
        actions.viewChanged(vm.onCreateDetailsChanged(updatedState));
    }

    function getPublicTitle(): string {
        // default to empty string instead of undefined so that the string 'undefined' is not displayed on screen
        const reportType = state.reportType?.name ?? "";
        const reportTitle = state.reportTitle ?? "";

        // the check is in place to avoid having ';' when neither reportType nor report title is set to a value
        return !reportType && !reportTitle ? "" : `${reportType}; ${reportTitle}`;
    }

    function updatePublicTitle(): void {
        updateVmAndState({ ...state, publicTitle: getPublicTitle() });
    }

    useEffect(() => {
        setState(vm.createDetails);
    }, [vm.createDetails]);

    return (
        <>
            <div className="mb-2">
                <Forms.Row>
                    <Forms.FullColumn>
                        <Forms.Label htmlFor="publicTitleField">Public Title</Forms.Label>
                        <Forms.ReadonlyTextInput id="publicTitleField" value={state.publicTitle} />
                    </Forms.FullColumn>
                </Forms.Row>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">NOPIMS/PIMS Details</h2>
                    <Forms.Row>
                        <Forms.ThirdColumn>
                            <Forms.TextInput
                                label="Item ID"
                                id="itemIdField"
                                maxLength={9}
                                valid={v.applyValidityForKendo("createDetails.ItemID")}
                                value={state.itemId}
                                onChange={(value) => setState({ ...state, itemId: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="createDetails.ItemID" validationModel={v} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="isConfidentialField">Confidentiality</Forms.Label>
                            <YesNoDropDownList
                                id="isConfidentialField"
                                valid={v.applyValidityForKendo("createDetails.IsConfidential")}
                                value={state.isConfidential}
                                trueValue="Confidential"
                                falseValue="Not Confidential"
                                onChange={(value) => updateVmAndState({ ...state, isConfidential: value })}
                            />
                            <ValidationMessageView name="createDetails.IsConfidential" validationModel={v} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="copiedToNopimsField">Is Copied to NOPIMS</Forms.Label>
                            <YesNoDropDownList
                                id="copiedToNopimsField"
                                allowNulls={true}
                                valid={v.applyValidityForKendo("fileAsset.IsCopiedToNopims")}
                                value={state.isCopiedToNopims}
                                onChange={(value) => updateVmAndState({ ...state, isCopiedToNopims: value })}
                            />
                        </Forms.ThirdColumn>
                        {(!vm.relatedActivityType || vm.relatedActivityType === CreateReportRelatedActivityTypeEnum.WellBorehole) && (
                            <Forms.FullColumn>
                                <Forms.Label htmlFor="wellBoreholeField">Borehole(s)</Forms.Label>
                                <KendoLargeMultiSelect
                                    id="wellBoreholeField"
                                    value={state.wellBoreholes}
                                    data={vm.referenceData.wellBoreholes}
                                    keyField="id"
                                    textField="name"
                                    valid={v.applyValidityForKendo("createDetails.RelatedActivities")}
                                    recordsToShow={100}
                                    onChange={(value) => setState({ ...state, wellBoreholes: value })}
                                    onBlur={updateVm}
                                />
                                {!vm.relatedActivityType && <ValidationMessageView name="createDetails.RelatedActivities" validationModel={v} />}
                                {vm.relatedActivityType && <ValidationMessageView name="createDetails.WellBoreholes" validationModel={v} />}
                            </Forms.FullColumn>
                        )}
                        {(!vm.relatedActivityType || vm.relatedActivityType === CreateReportRelatedActivityTypeEnum.SurveyAcquisition) && (
                            <Forms.FullColumn>
                                <Forms.Label htmlFor="surveyAcquisitionField">Acquisition(s)</Forms.Label>
                                <KendoLargeMultiSelect
                                    id="surveyAcquisitionField"
                                    value={state.surveyAcquisitions}
                                    data={vm.referenceData.surveyAcquisitions}
                                    keyField="id"
                                    textField="name"
                                    valid={v.applyValidityForKendo("createDetails.RelatedActivities")}
                                    recordsToShow={100}
                                    onChange={(value) => setState({ ...state, surveyAcquisitions: value })}
                                    onBlur={updateVm}
                                />
                                {!vm.relatedActivityType && <ValidationMessageView name="createDetails.RelatedActivities" validationModel={v} />}
                                {vm.relatedActivityType && <ValidationMessageView name="createDetails.SurveyAcquisitions" validationModel={v} />}
                            </Forms.FullColumn>
                        )}

                        {(!vm.relatedActivityType || vm.relatedActivityType === CreateReportRelatedActivityTypeEnum.ReprocessingProject) && (
                            <Forms.FullColumn>
                                <Forms.Label htmlFor="reprocessingProjectField">Project(s)</Forms.Label>
                                <KendoLargeMultiSelect
                                    id="reprocessingProjectField"
                                    value={state.reprocessingProjects}
                                    data={vm.referenceData.reprocessingProjects}
                                    keyField="id"
                                    textField="name"
                                    valid={v.applyValidityForKendo("createDetails.RelatedActivities")}
                                    recordsToShow={100}
                                    onChange={(value) => setState({ ...state, reprocessingProjects: value })}
                                    onBlur={updateVm}
                                />
                                {!vm.relatedActivityType && <ValidationMessageView name="createDetails.RelatedActivities" validationModel={v} />}
                                {vm.relatedActivityType && <ValidationMessageView name="createDetails.ReprocessingProjects" validationModel={v} />}
                            </Forms.FullColumn>
                        )}
                        <Forms.QuarterColumn>
                            <Forms.Label htmlFor="reportTypeField">Report Type (Data Type)</Forms.Label>
                            <KendoComboBox
                                id="reportTypeField"
                                value={state.reportType}
                                data={vm.referenceData.reportTypes}
                                keyField="id"
                                textField="name"
                                valid={v.applyValidityForKendo("createDetails.ReportType")}
                                filterable={true}
                                onChange={(event) => updateVmAndState({ ...state, reportType: event })}
                                onBlur={() => {
                                    updateVm();
                                    updatePublicTitle();
                                }}
                            />
                            <ValidationMessageView name="createDetails.ReportType" validationModel={v} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.TextInput
                                label="Tape Id"
                                id="tapeIdField"
                                maxLength={100}
                                valid={v.applyValidityForKendo("createDetails.TapeID")}
                                value={state.tapeId}
                                onChange={(value) => setState({ ...state, tapeId: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="createDetails.TapeID" validationModel={v} />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.Label htmlFor="mediaTypeField">Media Type</Forms.Label>
                            <KendoComboBox
                                id="mediaTypeField"
                                value={state.reportMediaType}
                                data={vm.referenceData.reportMediaTypes}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => updateVmAndState({ ...state, reportMediaType: value })}
                            />
                        </Forms.QuarterColumn>
                        <Forms.QuarterColumn>
                            <Forms.Label htmlFor="dataFormatField">Data Format (Asset Format)</Forms.Label>
                            <KendoComboBox
                                id="dataFormatField"
                                value={state.reportDataFormat}
                                data={vm.referenceData.reportDataFormats}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => updateVmAndState({ ...state, reportDataFormat: value })}
                            />
                        </Forms.QuarterColumn>
                        <Forms.FullColumn>
                            <Forms.TextArea
                                label="Report Title"
                                id="reportTitleField"
                                rows={1}
                                maxLength={4000}
                                valid={v.applyValidityForKendo("createDetails.ReportTitle")}
                                value={state.reportTitle}
                                onChange={(value) => setState({ ...state, reportTitle: value })}
                                onBlur={() => {
                                    updateVm();
                                    updatePublicTitle();
                                }}
                            />
                            <ValidationMessageView name="createDetails.ReportTitle" validationModel={v} />
                        </Forms.FullColumn>
                        <Forms.FullColumn>
                            <Forms.TextArea
                                label="Remarks (Asset Description)"
                                id="remarksField"
                                rows={1}
                                maxLength={1000}
                                valid={v.applyValidity("createDetails.Remarks")}
                                value={state.remarks}
                                onChange={(value) => setState({ ...state, remarks: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="createDetails.Remarks" validationModel={v} />
                        </Forms.FullColumn>
                        <Forms.FullColumn>
                            <Forms.TextArea
                                label="Confidentiality Remarks"
                                id="confidentialityRemarksField"
                                value={state.confidentialityRemarks}
                                rows={1}
                                maxLength={4000}
                                valid={v.applyValidityForKendo("createDetails.ConfidentialityRemarks")}
                                onChange={(value) => setState({ ...state, confidentialityRemarks: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="createDetails.ConfidentialityRemarks" validationModel={v} />
                        </Forms.FullColumn>
                        <Forms.FullColumn>
                            <Forms.TextArea
                                label="QC Remarks"
                                id="qcRemarks"
                                rows={1}
                                maxLength={4000}
                                valid={v.applyValidity("createDetails.QcRemarks")}
                                value={state.qcRemarks}
                                onChange={(value) => setState({ ...state, qcRemarks: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="createDetails.QcRemarks" validationModel={v} />
                        </Forms.FullColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="conditionStatusField">Condition Status</Forms.Label>
                            <KendoComboBox
                                id="conditionStatusField"
                                value={state.reportConditionStatus}
                                data={vm.referenceData.reportConditionStatuses}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => updateVmAndState({ ...state, reportConditionStatus: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="loanStatusField">Loan Status</Forms.Label>
                            <KendoComboBox
                                id="loanStatusField"
                                value={state.reportLoanStatus}
                                data={vm.referenceData.reportLoanStatuses}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => updateVmAndState({ ...state, reportLoanStatus: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="copyStatusField">Copy Status</Forms.Label>
                            <KendoComboBox
                                id="copyStatusField"
                                value={state.reportCopyStatus}
                                data={vm.referenceData.reportCopyStatuses}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => updateVmAndState({ ...state, reportCopyStatus: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="dataStatusField">Data Status</Forms.Label>
                            <KendoComboBox
                                id="dataStatusField"
                                value={state.reportDataStatus}
                                data={vm.referenceData.reportDataStatuses}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => updateVmAndState({ ...state, reportDataStatus: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="receivedDateField">Received Date (Received)</Forms.Label>
                            <DatePicker id="receivedDateField" value={state.receivedDate} onChange={(event) => setState({ ...state, receivedDate: event.value ?? undefined })} onBlur={updateVm} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="writtenDateField">Written Date</Forms.Label>
                            <DatePicker id="writtenDateField" value={state.writtenDate} onChange={(event) => setState({ ...state, writtenDate: event.value ?? undefined })} onBlur={updateVm} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.TextInput
                                label="Consignment"
                                id="consignmentField"
                                maxLength={1000}
                                valid={v.applyValidity("createDetails.Consignment")}
                                value={state.consignment}
                                onChange={(value) => setState({ ...state, consignment: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="createDetails.Consignment" validationModel={v} />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="rdsStatusField">RDDS Status (RDS Status)</Forms.Label>
                            <KendoComboBox
                                id="rdsStatusField"
                                value={state.rddsStatus}
                                data={vm.referenceData.rddsStatuses}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => updateVmAndState({ ...state, rddsStatus: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.FullColumn>
                            <Forms.TextArea
                                label="RDDS Comments (RDS Remarks)"
                                id="rddsComments"
                                maxLength={4000}
                                rows={3}
                                valid={v.applyValidity("createDetails.RDDSComments")}
                                value={state.rddsComments}
                                onChange={(value) => setState({ ...state, rddsComments: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="createDetails.RDDSComments" validationModel={v} />
                        </Forms.FullColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="companyField">Company</Forms.Label>
                            <KendoComboBox
                                id="companyField"
                                value={state.company}
                                data={vm.referenceData.contractorCompanies}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => setState({ ...state, company: value })}
                                onBlur={updateVm}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="contractorField">Contractor</Forms.Label>
                            <KendoComboBox
                                id="contractorField"
                                value={state.contractor}
                                data={vm.referenceData.contractorCompanies}
                                keyField="id"
                                textField="name"
                                filterable={true}
                                suggest={true}
                                onChange={(value) => setState({ ...state, contractor: value })}
                                onBlur={updateVm}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.TextInput
                                label="Author"
                                id="authorField"
                                maxLength={1000}
                                valid={v.applyValidityForKendo("createDetails.Author")}
                                value={state.author}
                                onChange={(value) => setState({ ...state, author: value })}
                                onBlur={updateVm}
                            />
                            <ValidationMessageView name="createDetails.Author" validationModel={v} />
                        </Forms.ThirdColumn>
                    </Forms.Row>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">NOPIMS Only Details</h2>
                    <Forms.Row>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="basicInterpTypeField">Basic Interp Type</Forms.Label>
                            <KendoComboBox
                                id="basicInterpTypeField"
                                value={state.reportBasicInterpType}
                                data={vm.referenceData.reportBasicInterpTypes}
                                keyField="id"
                                textField="name"
                                filterable={false}
                                suggest={false}
                                onChange={(value) => updateVmAndState({ ...state, reportBasicInterpType: value })}
                            />
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            {state.reportBasicInterpType?.value === BasicInterpTypeEnum.B || state.reportBasicInterpType?.value === BasicInterpTypeEnum.Bi ? (
                                <>
                                    <Forms.Label htmlFor="basicRelevantDateField">Basic Relevant Date</Forms.Label>
                                    <DatePicker
                                        id="basicRelevantDateField"
                                        value={state.basicRelevantDate}
                                        onChange={(event) => setState({ ...state, basicRelevantDate: event.value ?? undefined })}
                                        onBlur={updateVm}
                                    />
                                </>
                            ) : (
                                <>
                                    <Forms.ReadonlyField label="Basic Relevant Date" id="basicRelevantDateField" value={""} />
                                </>
                            )}
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            {state.reportBasicInterpType?.value === BasicInterpTypeEnum.I || state.reportBasicInterpType?.value === BasicInterpTypeEnum.Bi ? (
                                <>
                                    <Forms.Label htmlFor="interpRelevantDateField">Interp Relevant Date</Forms.Label>
                                    <DatePicker
                                        id="interpRelevantDateField"
                                        value={state.interpRelevantDate}
                                        onChange={(event) => setState({ ...state, interpRelevantDate: event.value ?? undefined })}
                                        onBlur={updateVm}
                                    />
                                </>
                            ) : (
                                <>
                                    <Forms.ReadonlyField label="Interp Relevant Date" id="interpRelevantDateField" value={""} />
                                </>
                            )}
                        </Forms.ThirdColumn>
                        <Forms.ThirdColumn>
                            <Forms.Label htmlFor="releasedDateField">Released Date</Forms.Label>
                            <DatePicker id="releasedDateField" value={state.releasedDate} onChange={(event) => setState({ ...state, releasedDate: event.value ?? undefined })} onBlur={updateVm} />
                        </Forms.ThirdColumn>
                    </Forms.Row>
                </div>
            </div>
        </>
    );
}
