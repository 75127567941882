// common
import * as Validation from "../../../../../common/validation/ValidationModel";
// models
import { IRootViewModel } from "./models";

export default function validate(vm: IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);
    const surveyDataDetails = vm.surveyDataDetails;

    const validItemIdRegex = /^[DEILMPYGXQRS][\d]{8}$/;

    if (Validation.isNullOrWhiteSpace(surveyDataDetails.archiveId)) {
        v.addError("SurveyDataDetails.ArchiveID", "Item ID is required.");
    } else if (!validItemIdRegex.test(surveyDataDetails.archiveId!)) {
        v.addError(
            "SurveyDataDetails.ArchiveID",
            "Item ID is invalid. It must be 9 characters long. Starting with either one of the following characters D, E, I, L, M, P, Y, G, X, Q, R or S, and the remaining characters being all digits."
        );
    }

    if (!surveyDataDetails.surveyDataType) {
        v.addError("SurveyDataDetails.SurveyDataType", "Data Type is required.");
    }

    if (surveyDataDetails.isConfidential === undefined) {
        v.addError("SurveyDataDetails.IsConfidential", "Confidentiality is required.");
    }

    if (surveyDataDetails.title && surveyDataDetails.title.length > 1000) {
        v.addError("SurveyDataDetails.SurveyDataTitle", "Survey Data Title must be 1,000 characters or less.");
    }

    if (Validation.isNullOrWhiteSpace(surveyDataDetails.confidentialityRemarks)) {
        v.addError("SurveyDataDetails.ConfidentialityRemarks", "Confidentiality Remarks are required.");
    } else if (surveyDataDetails.confidentialityRemarks && surveyDataDetails.confidentialityRemarks.length > 4000) {
        v.addError("SurveyDataDetails.ConfidentialityRemarks", "Confidentiality Remarks must be 4,000 characters or less.");
    }

    if (surveyDataDetails.inlineRange && surveyDataDetails.inlineRange.length > 100) {
        v.addError("SurveyDataDetails.InlineRange", "Inline Range must be 100 characters or less.");
    }

    if (surveyDataDetails.crossLineRange && surveyDataDetails.crossLineRange.length > 100) {
        v.addError("SurveyDataDetails.CrossLineRange", "Crossline Range must be 100 characters or less.");
    }

    return v;
}
