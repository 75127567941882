// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { IGeoscienceAustraliaReduxState } from "../../../../../geoscienceAustraliaReduxRegistration";
// api
import * as ClientFactory from "../../../../../../api/clientFactory";
import * as CallHelper from "../../../../../../api/callHelper";
import * as Client from "../../../../../../api/Client";
// common
import * as shellHelper from "../../../../../../common/shell/shellHelper";
import * as LogHelper from "../../../../../../common/LogHelper";

const getRootViewModel = (state: IGeoscienceAustraliaReduxState) => state.GeoscienceAustralia_ReportsAndData_SurveyDataFileAssets;

export default function* update() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        const saveResult: CallHelper.CallResult<Client.UpdateGeoscienceAustraliaSurveyDataFileAssetsResponseDto> = yield call(async () => await updateFileAssets(vm));

        if (saveResult.IsSuccess) {
            // success
            let updatedVm = vm;
            const r = saveResult.Response!;
            if (r.statusMessages.isSuccess) {
                const getFileAssetsResponse: CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveyDataFileAssetsResponseDto> = yield call(async () => await getSurveyDataFileAssets(vm));
                updatedVm = vm.refreshFileAssets(getFileAssetsResponse.Response!);
            }
            yield put(Actions.actionFactory.sagaCompleted(updatedVm.refreshUpdateResponse(saveResult.Response!)));
        } else if (saveResult.IsConflict) {
            // conflict
            yield put(Actions.actionFactory.sagaCompleted(vm.refreshConflict()));
        } else {
            // all other errors
            saveResult.ShowToastNotification();
            return;
        }
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}

async function updateFileAssets(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.UpdateGeoscienceAustraliaSurveyDataFileAssetsResponseDto>> {
    const request = new Client.UpdateGeoscienceAustraliaSurveyDataFileAssetsRequestDto({
        surveyDataId: vm.surveyDataId!,
        surveyDataVersionNumber: vm.surveyDataVersionNumber!,
        tapeId: vm.surveyDataFileAssets.tapeId,
        surveyDataAssetFormatTypeId: vm.surveyDataFileAssets.surveyDataAssetFormatType?.id,
        surveyDataMediaTypeId: vm.surveyDataFileAssets.surveyDataMediaType?.id,
        remarks: vm.surveyDataFileAssets.remarks,
        surveyDataConditionStatusId: vm.surveyDataFileAssets.surveyDataConditionStatus?.id,
        qcRemarks: vm.surveyDataFileAssets.qcRemarks,
        surveyDataCopyStatusId: vm.surveyDataFileAssets.surveyDataCopyStatus?.id,
        surveyDataLoanStatusId: vm.surveyDataFileAssets.surveyDataLoanStatus?.id,
        surveyDataRddsStatusId: vm.surveyDataFileAssets.surveyDataRddsStatus?.id,
        rddsComments: vm.surveyDataFileAssets.rddsComments,
        surveyDataDataStatusId: vm.surveyDataFileAssets.surveyDataDataStatus?.id,
        consignment: vm.surveyDataFileAssets.consignment,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.updateGeoscienceAustraliaSurveyDataFileAssets(request));
}

async function getSurveyDataFileAssets(vm: Models.IRootViewModel): Promise<CallHelper.CallResult<Client.GetGeoscienceAustraliaSurveyDataFileAssetsResponseDto>> {
    const request = new Client.GetGeoscienceAustraliaSurveyDataFileAssetsRequestDto({
        surveyDataId: vm.surveyDataId!,
    });

    const client = await ClientFactory.createGeoscienceAustraliaSurveyDataClient();
    return await CallHelper.call(() => client.getGeoscienceAustraliaSurveyDataFileAssets(request));
}
