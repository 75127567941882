// framework
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process, State } from "@progress/kendo-data-query";
import CustomGridCell from "../../../../../../common/kendoGrid/CustomGridCell";
import { createGridColumnFilterMenu } from "../../../../../../common/kendoGrid/GridColumnFilterMenu";
import { createGridColumnCheckboxFilterMenu } from "../../../../../../common/kendoGrid/GridColumnCheckboxFilterMenu";
// models
import { IRootViewModel } from "../../models/models";
// common
import * as GlobalHelpers from "../../../../../../common/GlobalHelpers";
import * as geoscienceAustraliaRouteHelper from "../../../../../geoscienceAustraliaRouteHelper";

export default function SurveyListGridView(props: { vm: IRootViewModel }): JSX.Element {
    const { vm } = props;

    const initialState: State = {
        filter: { logic: "and", filters: [] },
        skip: 0,
        take: 20,
    };

    const [gridState, setGridState] = useState(initialState);
    // reset grid state when data changes
    useEffect(() => {
        setGridState(initialState);
    }, [vm.surveys]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid
            pageable
            sortable
            resizable
            navigatable
            data={process(vm.surveys, gridState)}
            {...gridState}
            onDataStateChange={(e) => {
                setGridState(e.dataState);
            }}
        >
            <GridColumn
                field="identifier"
                title="Survey ID"
                width={150}
                cell={(props) => (
                    <CustomGridCell gridCellProps={props}>
                        <Link to={geoscienceAustraliaRouteHelper.surveyDetails(props.dataItem.id)} title="Click to view Survey details.">
                            {props.dataItem.identifier}
                        </Link>
                    </CustomGridCell>
                )}
                filter="text"
                columnMenu={createGridColumnFilterMenu}
            />
            <GridColumn field="name" title="Name" width={300} filter="text" columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="status" title="Status" width={150} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveys)} />
            <GridColumn field="type" title="Type" width={150} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveys)} />
            <GridColumn field="operator" title="Operator" width={250} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveys)} />
            <GridColumn field="startDate" title="Start Date" width={160} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="endDate" title="End Date" width={150} filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} columnMenu={createGridColumnFilterMenu} />
            <GridColumn field="legislation" title="Legislation" width={160} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveys)} />
            <GridColumn field="basins" title="Basin(s)" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveys)} />
            <GridColumn field="countries" title="Countries" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveys)} />
            <GridColumn field="regions" title="Region(s)" width={200} filter="text" columnMenu={(p) => createGridColumnCheckboxFilterMenu(p, vm.surveys)} />
        </Grid>
    );
}
